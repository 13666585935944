@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'NexaBold';
    src: url(../src/assets/fonts/Nexa-Heavy.ttf);
}

@font-face {
    font-family: 'Nexa';
    src: url(../src/assets/fonts/Nexa-ExtraLight.ttf);
}

.heading1{
    font-family: Nexa;
    font-size: 60px;
    font-weight: 1000;
}

.heading2{
    font-family: NexaBold;
    font-size: 60px;
    line-height: 100%;
}

.heading3{
    font-family: Nexa;
    font-size: 48px;
    font-weight: 1000;
}

.heading4{
    font-family: NexaBold;
    font-size: 48px;
}

.heading5{
    font-family: NexaBold;
    font-size: 34px;
}

.heading6{
    font-family: Nexa;
    font-size: 34px;
    font-weight: 1000;
}

.heading7{
    font-family: Nexa;
    font-size: 34px;
    font-weight: 600;
}

.heading8{
    font-family: NexaBold;
    font-size: 24px;
}

.heading9{
    font-family: Nexa;
    font-size: 24px;
    font-weight: 1000;
}

.body1{
    font-family: Nexa;
    font-size: 16px;
    font-weight: 600;
}

.body2{
    font-family: NexaBold;
    font-size: 16px;
}

.body3{
    font-family: Nexa;
    font-size: 14px;
    font-weight: 600;
}

.body4{
    font-family: NexaBold;
    font-size: 14px;
}

.carousel .slide img{
    width: auto !important;
}
.divCircle { 
height: 48px;
width: 48px; 
border-radius: 50%;
display: inline-block; 
  }

.fontNexa{
    font-family: NexaBold;
  }

  @media (prefers-color-scheme: dark) {
    body{
        background: #fff;
    }
}